import React from "react";
import Meta from "./../components/Meta";
import Section from "./../components/Section";
import SectionHeader from "../components/SectionHeader";
import AboutSection from "../components/AboutSection"
import ContactSection from "../components/ContactSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <Section className="container mx-auto px-4 pt-10">
        <SectionHeader
          title="Orange Industrial"
          subtitle="Built for impact."
          strapline=""
          className="text-center mb-4"
        />
        <AboutSection
          size="md"
          bgColor="bg-white"
          bgImage=""
          bgImageOpacity={1}
          textColor=""
        />
      </Section>
      <ContactSection
        title="Get in touch"
        subtitle=""
        strapline="We reply in 24hrs"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Section from "./Section";
import { Link } from "./../util/router";
import TextField from "./TextField";
import Button from "./Button";
import newsletter from "./../util/newsletter";

function Footer(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={props.sticky && "mt-auto"}
    >
      <footer className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Product
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                //{ url: "/faq", name: "FAQ" },
                // { url: "/tech", name: "Technology" },
                { url: "/solutions", name: "Solutions" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-600 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Company
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                { url: "/about", name: "About" },
                { url: "/contact", name: "Contact" },
                { url: "/legal/privacy-policy", name: "Privacy Policy" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-600 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Join Our Newsletter
            </h4>

            {subscribed === true && (
              <div className="mt-3">You are now subscribed!</div>
            )}

            {subscribed === false && (
              <form
                className="mt-6 flex items-start space-x-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grow">
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    error={errors.email}
                    size="sm"
                    inputRef={register({
                      required: "Please enter an email address",
                    })}
                  />
                </div>
                <Button type="submit" size="sm">
                  Subscribe
                </Button>
              </form>
            )}
          </div>
        </div>
        <div className="text-center text-xs text-gray-400 mt-20">
          Copyright © 2024 Orange Recycling, Inc. All rights reserved.
        </div>
      </footer>
    </Section>
  );
}

export default Footer;

import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import StatsSection from "../components/StatsSection";
import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        title="Digital infrastructure for the waste industry"
        subtitle="Identify and eliminate waste and improve operational efficiency with high-resolution data from your entire waste stream."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <StatsSection/>
      <FeaturesSection
        title="Real-time composition data from bin to bale."
        subtitle="Optimize your waste operation with real-time material stream data starting at the point of collection."
        //strapline="Created with passion"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            Ready to revolutionize your waste operation?
          </>
        }
        subtitle="Orange Industrial's cutting-edge technology can help you transform your operation, decrease contamination, and increase diversion rates."
        strapline=""
        size="sm"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default IndexPage;

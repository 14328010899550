import React from "react";
import Section from "./Section";


function AboutSection(props) {
  const items = [
    {
      title: "Mission",
      description:
        "At Orange Industrial, our mission is clear—to drive positive change in the waste industry by reducing waste and providing essential data-driven insights. We believe in empowering recycling jurisdictions and recovery operations with the tools they need to enhance efficiency, efficacy, and profitability.",
    },
    {
      title: "Built for Impact",
      description: "Orange Industrial builds solutions that make a tangible difference. Whether it's slashing contamination rates or offering operators unprecedented visibility into commodity flows, our technology is designed to drive efficiency and enable sustainability in recycling and green waste businesses."
    },
    {
      title: "Long-Term Partnership",
      description: "We aspire to be more than just a vendor; we aim to be a trusted long-term partner for recycling jurisdictions, haulers, material recovery facilities, and recyclers. By providing consistent and accurate insights into material streams, we help support diversion efforts, reduce contaminants, and increase the output of recoverable materials. We firmly believe that data is the key to improvement, and we are committed to reshaping the future of of a more sustainable waste management industry."
    },
  ];
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-12 container">
        <div className="space-y-16 container">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-12">
            {items.map((item, index) => (
              <div key={index}>
                <h3 className="flex items-center space-x-2 text-xl font-bold uppercase tracking-wide mb-4">
                  <span>{item.title}</span>
                </h3>
                <p className="leading-relaxed text-lg text-gray-600">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default AboutSection;


import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";

function HeroSection(props) {
  return (
    <Section
      className="min-h-[40vh] sm:min-h-[40vh] md:min-h-[50vh] lg:min-h-[50vh] xl:min-h-[50vh]"
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white font-extrabold text-center w-full px-4 md:px-20">
        <span className="hidden sm:hidden md:hidden lg:block xl:block text-6xl xl:text-7xl">Digital infrastructure for the waste industry</span>
        <span className="hidden sm:hidden md:block lg:hidden xl:hidden text-5xl">Digital infrastructure for the waste industry</span>
        <span className="hidden sm:block md:hidden lg:hidden xl:hidden text-4xl">Digital infrastructure for the waste industry</span>
        <span className="block sm:hidden md:hidden lg:hidden xl:hidden text-3xl">Digital infrastructure for the waste industry</span>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          zIndex: 3
        }}
      ></div>
      <div // blur
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjusted opacity here
          zIndex: 2,
          backdropFilter: 'blur(2.5px)' // Added blur
        }}
      ></div>
      <video // video
        autoPlay 
        loop 
        muted 
        playsInline 
        className="absolute w-full h-full object-cover"
        style={{
          top: 0, left: 0, zIndex: 1, opacity: 1 // Adjusted transparency here
        }}
      >
        <source src="output.webm" type="video/webm" />
      </video>

    </Section>
  );
}
export default HeroSection;

import React from "react";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import ContactPage from "./contact";
import TechPage from "./tech";
import SolutionsPage from "./solutions";
import AuthPage from "./auth";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <Navbar bgColor="bg-white" />

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/tech" component={TechPage} />

              <Route exact path="/solutions" component={SolutionsPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/legal/:section" component={LegalPage} />


              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              size="md"
              bgColor="bg-white"
              bgImage=""
              bgImageOpacity={1}
              textColor=""
              sticky={true}
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;

import React from "react";
import Meta from "../components/Meta";
import SolutionsSection from "../components/SolutionsSection";
import tech_content from "../content/tech.json"
import FeaturesSectionSideLeft from "../components/FeaturesSectionSideLeft";

function TechPage(props) {
  return (
    <>
      <Meta title="Orange Industrial - Technology" description="Learn about our technology" />
      <FeaturesSectionSideLeft/>
    </>
  );
}

export default TechPage;

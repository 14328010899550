import React from "react";
import Meta from "../components/Meta";
import SolutionsSection from "../components/SolutionsSection";
import solutions_content from "../content/solutions.json"

function SolutionsPage(props) {
  return (
    <>
      <Meta title="Solutions" description="Learn about our point-of-collection solutions" />
      <SolutionsSection solutions={solutions_content.solutions} />
    </>
  );
}

export default SolutionsPage;

import React from "react";
import {
  ChartBarIcon,
  ClipboardDocumentCheckIcon,
  ViewfinderCircleIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = [
    {
      title: "Identify contamination at the source",
      description:
        "Detect and attribute contamination at the source",
      icon: ViewfinderCircleIcon,
      iconColor: "orange",
    },
    {
      title: "Deliver continuous feedback to your customers",
      description:
        "Reduce your contamination rate by providing continuous feedback to your customers.",
      icon: ChatBubbleLeftRightIcon,
      iconColor: "green",
    },
    {
      title: "Understand your material stream",
      description:
        "Optimize your operations with real-time, bin-resolution material composition data.",
      icon: ChartBarIcon,
      iconColor: "brown",
    },
    {
      title: "Automate reporting & compliance",
      description:
        "Automate your reporting and compliance obligations with ease.",
      icon: ClipboardDocumentCheckIcon,
      iconColor: "blue",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-5">
          {features.map((feature, index) => (
            <div
              className="group p-8 transition ease-out duration-200 rounded-2xl text-center"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
              >
                <feature.icon />
              </FeatureIcon2>
              <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
              <p className="leading-relaxed text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;

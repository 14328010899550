import React, { useEffect } from "react";
import Button from "./Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function SolutionsSection(props) {
    const { solutions, title, subtitle, strapline, size, bgColor, bgImage, bgImageOpacity, textColor } = props;

    useEffect(() => {
        const hash = window.location.hash.toLowerCase(); // Convert hash to lowercase
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <Section
            className="min-h-screen flex flex-col justify-center"
            size={size}
            bgColor={bgColor}
            bgImage={bgImage}
            bgImageOpacity={bgImageOpacity}
            textColor={textColor}
        >
            <div className="container mx-auto px-4">
                <SectionHeader
                    title=""
                    subtitle={subtitle}
                    strapline=""
                    className="text-left mb-12 p-8"
                />
                <div className="grid grid-cols-1">
                    {solutions.map((solution, index) => (
                        <div key={index} id={`solution${index}`.toLowerCase()} className="flex flex-col bg-white overflow-hidden border-b border-t border-gray-100 pt-12 pb-12">
                            <div className="flex flex-col md:flex-row items-start w-full">
                                <div className={`w-full md:w-1/2 ${index % 2 === 0 ? 'md:order-first' : 'md:order-last'} pb-8 pr-8 pl-8`}>
                                    <img src={solution.imageUrl} alt={solution.title} className="w-full h-auto object-cover rounded-lg" />
                                </div>
                                <div className="pr-4 pl-4 w-full md:w-1/2">
                                    <div className="flex flex-col w-full justify-start">
                                        <h3 className="text-2xl font-semibold mb-2 text-left">{solution.title}</h3>
                                        <p className="text-m text-gray-700 mb-4">{solution.problem.join(" ")}</p>
                                        <p className="text-m text-gray-700 mb-4">{solution.solution.join(" ")}</p>
                                    </div>
                                    <div className="w-full">
                                        <div className={`grid grid-cols-1`}>
                                            {solution.bulletPoints.map((point, index) => (
                                                <div key={index} className="text-m text-gray-700 p-2 rounded-lg flex">
                                                    <span className="mr-2 p-2">
                                                        <svg className="h-4 w-4 text-highviz-default" fill="none" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="9" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                                        </svg>
                                                    </span>
                                                    <p className="text-s text-gray-700">{point}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="mt-8"
                                        onClick={() => window.location.href = '/contact'}
                                    >
                                        Learn More
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Section>
    );
}
export default SolutionsSection;
